import React from 'react'
export const StorkoebTexts = [
    {
        title: 'Lille kødpakke',
        text: (
                    <p>
                        1 kg ribbensteg <br />
                        4 stk. skinkeschnitzler <br />
                        4 stk. svinekoteletter <br />
                        2 x 500 g flæskefars <br />
                        2 x 500 g gullashkød <br />
                        2 x 500 g medisterpølse <br />
                        2 x 500 g oksefars <br />
                        2 x 600 g stegeflæsk (skiver) <br />
                        500 g bacon <br />
                        <br />
                        Pris i alt: 895 kr.
                    </p>
                )
    },
    {
        title: 'Stor kødpakke',
        text: (<p>
                    1 stk. forloren hare <br />
                    1 stk. svinemørbrad <br />
                    1 kg oksetykkam (gl.dags oksesteg) <br />
                    1 kg stegeflæsk <br />
                    1 kg svinekam <br />
                    4 stk. bøffer u/fedt <br />
                    4 stk. kalveschnitzler <br />
                    4 stk. skinkeschnitzler <br />
                    4 stk. svinekoteletter <br />
                    6 stk. frankfurter <br />
                    2 x 500 g hakket oksekød <br />
                    2 x 500 g hakket svinekød <br />
                    500 g oksekød i strimler <br />
                    <br />
                    Pris i alt: 1995 kr.
                </p>)
    },
    /*{
        title: 'Grillpakke',
        text: (
            <p>
                4 stk. bøffer med fedtkant <br />
                4 stk. hakkebøffer med peberkant <br />
                4 stk. frankfurter <br />
                4 stk. ostepølser <br />
                4 stk. marinerede nakkekoteletter <br />
                8 stk. marinerede forkogte revlsben <br />
                <br />
                Pris i alt: 825 kr.
            </p>
        )
    },*/
    {
        title: 'Farspakke',
        text: (
            <p>
                3 kg hakket oksekød <br />
                3 kg hakket svinekød <br />
                3 kg medisterpølse <br />
                <br />
                Pris i alt: 875 kr.
            </p>
        )
    },
    {
        title: 'Øvrige',
        text: (
            <p>
                Hakket oksekød: <br />
                &nbsp;1 kg &emsp; 140 kr. pr. kg <br />
                &nbsp;3 kg &emsp; 135 kr. pr. kg <br />
                &nbsp;5 kg &emsp; 130 kr. pr. kg <br />
                10 kg &emsp;125 kr. pr. kg. <br />
                <br />
                Hakket svinekød: <br />
                &nbsp;1 kg &emsp; 85 kr. pr. kg <br/>
                &nbsp;3 kg &emsp; 80 kr. pr. kg <br />
                &nbsp;5 kg &emsp; 75 kr. pr. kg <br />
                10 kg &emsp;70 kr. pr. kg <br />
                <br />
                Hakket kalv/flæsk: <br />
                &nbsp;1 kg &emsp; 140 kr. pr. kg <br />
                &nbsp;3 kg &emsp; 135 kr. pr. kg <br />
                &nbsp;5 kg &emsp; 130 kr. pr. kg <br />
                10 kg &emsp;125 kr. pr. kg. <br />
                <br />
                Medister: <br />
                &nbsp;1 kg &emsp; 85 kr. pr. kg <br/>
                &nbsp;3 kg &emsp; 80 kr. pr. kg <br />
                &nbsp;5 kg &emsp; 75 kr. pr. kg <br />
                10 kg &emsp;70 kr. pr. kg <br />
                <br />
                1/2 kvie, parteret - kontakt os for priser <br />
                1/2 gris, parteret - kontakt os for priser <br />
                Oksepistol, parteret - kontakt os for priser
            </p>
        )
    }
]
