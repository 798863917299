import React from "react";

const OpeningHoursTable = () => {

    return (
            <div className="bg-red text-white p-6 rounded-2xl text-center">
                <table className='w-7/12 mx-auto table-auto'>
                    <caption>
                        <p>ÅBNINGSTIDER</p>
                    </caption>
                        <tbody>
                        <tr className='border-b border-b-1'>
                            <td>Mandag</td>
                            <td>Lukket</td>
                        </tr>
                        <tr className='border-b border-b-1'>
                            <td>Tirsdag</td>
                            <td>Lukket</td>
                        </tr>
                        <tr className='border-b border-b-1'>
                            <td>Onsdag</td>
                            <td>Lukket</td>
                        </tr>
                        <tr className='border-b border-b-1'>
                            <td>Torsdag</td>
                            <td>Lukket</td>
                        </tr>
                        <tr className='border-b border-b-1'>
                            <td>Fredag</td>
                            <td>10-16</td>
                        </tr>
                        <tr className='border-b border-b-1'>
                            <td>Lørdag</td>
                            <td>Lukket</td>
                        </tr>
                        <tr className='border-b border-b-1'>
                            <td>Søndag</td>
                            <td>Lukket</td>
                        </tr>
                        </tbody>
                </table>
            </div>
    )
}

export default OpeningHoursTable;